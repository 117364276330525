






import VoucherStepper from "@/components/voucher/VoucherStepper.vue";
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  components: { VoucherStepper },
  name: "VoucherForm",
});
